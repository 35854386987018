import React from "react"
import Layout from "./layout"
import SEO from "./seo"

import Subnavigation from "./sub-navigation"
import HeaderSmall from "./header-small"

export default function PageSmall({ children, title, pageTitle }) {
  return (
    <Layout header={false}>
      <SEO title={pageTitle} />

      <Subnavigation />

      <article className="pt-24 pb-24">
        <HeaderSmall />

        <div className="mt-8 md:w-5/6 mx-auto">
          <h1 className="text-4xl md:text-6xl text-center mt-8 md:mb-16 px-6">
            {pageTitle}
          </h1>
        </div>

        {children}
      </article>
    </Layout>
  )
}
