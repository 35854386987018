import React, { useState } from "react"

import PageSmall from "./../components/page-small"

import Swipercontainer from "./../components/swiperContainer"
import FlareSeparator from "./../components/flare-separator"

import scores from "./../data/box-scores"
import "./../styles/box-scores.scss"

const SingleWeekResults = ({ scores = [], weekNumber = 0 }) => {
  const [currentSlide, setCurrentslide] = useState(0)

  const sliderChange = id => {
    setCurrentslide(id)
  }

  return (
    <div className="pt-16 mb-24" id={`week-${weekNumber}`}>
      <div className="mx-auto mt-8 md:w-5/6">
        <h1 className="px-6 mt-8 text-4xl text-center md:text-6xl md:mb-16">
          WEEK {weekNumber} RESULTS
        </h1>
      </div>

      <Swipercontainer
        name="schedule"
        changeSlide={currentSlide}
        sliderChange={sliderChange}
      >
        {scores.map((el, index) => (
          <div
            className="p-0 sm:px-16"
            key={`week-${weekNumber}-slide-${index}`}
          >
            <img src={el.image} alt="Score" className="mx-auto" />
          </div>
        ))}
      </Swipercontainer>
    </div>
  )
}

const IndexPage = () => {
  return (
    <>
      <PageSmall title="Box Scores" pageTitle="">
        {scores.map((score, weekNumber) => (
          <SingleWeekResults
            scores={score}
            weekNumber={scores.length - weekNumber}
            key={`week-${weekNumber}`}
          />
        ))}

        <FlareSeparator />
      </PageSmall>
    </>
  )
}

export default IndexPage
