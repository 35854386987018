import wk1GillanRudd from "./../images/box-scores/ASFFL-wk1-gillan-rudd.jpg"
import wk1HemsworthBerry from "./../images/box-scores/ASFFL-wk1-hemsworth-berry.jpg"
import wk1LiuPratt from "./../images/box-scores/ASFFL-wk1-liu-pratt.jpg"
import wk1MackieHolland from "./../images/box-scores/ASFFL-wk1-mackie-holland.jpg"
import wk1OlsenKlementieff from "./../images/box-scores/ASFFL-wk1-olsen-klementieff.jpg"
import wk1ReynoldsEvans from "./../images/box-scores/ASFFL-wk1-reynolds-evans.jpg"
import wk1RussoDowney from "./../images/box-scores/ASFFL-wk1-russo-downey.jpg"

import wk2BerryHolland from "./../images/box-scores/ASFFL-wk2-berry-holland.jpg"
import wk2DowneyReynolds from "./../images/box-scores/ASFFL-wk2-downey-reynolds.jpg"
import wk2GillanHemsworth from "./../images/box-scores/ASFFL-wk2-gillan-hemsworth.jpg"
import wk2KlementieffEvans from "./../images/box-scores/ASFFL-wk2-klementieff-evans.jpg"
import wk2LiuRusso from "./../images/box-scores/ASFFL-wk2-liu-russo.jpg"
import wk2PrattMackie from "./../images/box-scores/ASFFL-wk2-pratt-mackie.jpg"
import wk2RuddOlsen from "./../images/box-scores/ASFFL-wk2-rudd-olsen.jpg"

import wk3BerryPratt from "./../images/box-scores/ASFFL-wk3_berry-pratt.jpg"
import wk3EvansRudd from "./../images/box-scores/ASFFL-wk3_evans-rudd.jpg"
import wk3HemsworthOlsen from "./../images/box-scores/ASFFL-wk3_hemsworth-olsen.jpg"
import wk3HollandGillan from "./../images/box-scores/ASFFL-wk3_holland-gillan.jpg"
import wk3KlementieffDowney from "./../images/box-scores/ASFFL-wk3_klementieff-downey.jpg"
import wk3MackieLiu from "./../images/box-scores/ASFFL-wk3_mackie-liu.jpg"
import wk3ReynoldsRusso from "./../images/box-scores/ASFFL-wk3_reynolds-russo.jpg"

import wk4BerryLiu from "./../images/box-scores/ASFFL_BoxScores_Wk4_berry-liu.png"
import wk4GillanPratt from "./../images/box-scores/ASFFL_BoxScores_Wk4_gillan-pratt.png"
import wk4HemsHolland from "./../images/box-scores/ASFFL_BoxScores_Wk4_hems-holland.png"
import wk4MackieReynolds from "./../images/box-scores/ASFFL_BoxScores_Wk4_mackie-reynolds.png"
import wk4OlsenEvans from "./../images/box-scores/ASFFL_BoxScores_Wk4_olsen-evans.png"
import wk4RuddDowney from "./../images/box-scores/ASFFL_BoxScores_Wk4_rudd-downey.png"
import wk4RussoKlementieff from "./../images/box-scores/ASFFL_BoxScores_Wk4_russo-klementieff.png"

import wk5BerryMackie from "./../images/box-scores/ASFFL_BoxScores_Wk5_berry-mackie.jpg"
import wk5DowneyOlsen from "./../images/box-scores/ASFFL_BoxScores_Wk5_downey-olsen.jpg"
import wk5GillanLiu from "./../images/box-scores/ASFFL_BoxScores_Wk5_gillan-liu.jpg"
import wk5HollandEvans from "./../images/box-scores/ASFFL_BoxScores_Wk5_holland-evans.jpg"
import wk5PrattHemsworth from "./../images/box-scores/ASFFL_BoxScores_Wk5_pratt-hemsworth.jpg"
import wk5ReynoldsKlementieff from "./../images/box-scores/ASFFL_BoxScores_Wk5_reynolds-klementieff.jpg"
import wk5RussoRudd from "./../images/box-scores/ASFFL_BoxScores_Wk5_russo-rudd.jpg"

import wk6BerryKlementieff from "./../images/box-scores/ASFFL_BoxScore_Wk6_berry-klementieff.jpg"
import wk6EvansDowney from "./../images/box-scores/ASFFL_BoxScore_Wk6_evans-downey.jpg"
import wk6HemsworthLiu from "./../images/box-scores/ASFFL_BoxScore_Wk6_hemsworth-liu.jpg"
import wk6HollandPratt from "./../images/box-scores/ASFFL_BoxScore_Wk6_holland-pratt.jpg"
import wk6MackieGillan from "./../images/box-scores/ASFFL_BoxScore_Wk6_mackie-gillan.jpg"
import wk6OlsenRusso from "./../images/box-scores/ASFFL_BoxScore_Wk6_olsen-russo.jpg"
import wk6ReynoldsRudd from "./../images/box-scores/ASFFL_BoxScore_Wk6_reynolds-rudd.jpg"

import berryGillan from "./../images/box-scores/ASFFL_Box_wk7_berry-gillan.jpg"
import hemsworthMackie from "./../images/box-scores/ASFFL_Box_wk7_hemsworth-mackie.jpg"
import hollandLiu from "./../images/box-scores/ASFFL_Box_wk7_holland-liu.jpg"
import olsenReynolds from "./../images/box-scores/ASFFL_Box_wk7_olsen-reynolds.jpg"
import prattDowney from "./../images/box-scores/ASFFL_Box_wk7_pratt-downey.jpg"
import ruddKlementieff from "./../images/box-scores/ASFFL_Box_wk7_rudd-klementieff.jpg"
import russoEvans from "./../images/box-scores/ASFFL_Box_wk7_russo-evans.jpg"

import wk8BerryDowney from "./../images/box-scores/ASFFL_Scores_wk8_berry-downey.jpg"
import wk8EvansMackie from "./../images/box-scores/ASFFL_Scores_wk8_evans-mackie.jpg"
import wk8GillanRusso from "./../images/box-scores/ASFFL_Scores_wk8_gillan-russo.jpg"
import wk8KlementieffHolland from "./../images/box-scores/ASFFL_Scores_wk8_klementieff-holland.jpg"
import wk8OlsenLiu from "./../images/box-scores/ASFFL_Scores_wk8_olsen-liu.jpg"
import wk8PrattRudd from "./../images/box-scores/ASFFL_Scores_wk8_pratt-rudd.jpg"
import wk8ReynoldsHemsworth from "./../images/box-scores/ASFFL_Scores_wk8_reynolds-hemsworth.jpg"

import wk9BerryRudd from "./../images/box-scores/ASFFL_Scores_wk9_berry-rudd.jpg"
import wk9GillanOlsen from "./../images/box-scores/ASFFL_Scores_wk9_gillan-olsen.jpg"
import wk9HemswoorthEvans from "./../images/box-scores/ASFFL_Scores_wk9_hemswoorth-evans.jpg"
import wk9HollandDowney from "./../images/box-scores/ASFFL_Scores_wk9_holland-downey.jpg"
import wk9LiuReynolds from "./../images/box-scores/ASFFL_Scores_wk9_liu-reynolds.jpg"
import wk9MackieKlementieff from "./../images/box-scores/ASFFL_Scores_wk9_mackie-klementieff.jpg"
import wk9PrattRusso from "./../images/box-scores/ASFFL_Scores_wk9_pratt-russo.jpg"

import wk10RuddHolland from "./../images/box-scores/ASFFL_Scores_wk10-Rudd-Holland.jpg"
import wk10EvansLiu from "./../images/box-scores/ASFFL_Scores_wk10-evans-liu.jpg"
import wk10KlementieffHemsworth from "./../images/box-scores/ASFFL_Scores_wk10-klementieff-hemsworth.jpg"
import wk10OlsenPratt from "./../images/box-scores/ASFFL_Scores_wk10-olsen-pratt.jpg"
import wk10ReynoldsGillan from "./../images/box-scores/ASFFL_Scores_wk10-reynolds-gillan.jpg"
import wk10RussoBerry from "./../images/box-scores/ASFFL_Scores_wk10-russo-berry.jpg"
import wk10DowneymackieJpg from "./../images/box-scores/ASFFL_Scores_wk10_downey-mackie.jpg"

import wk11BerryOlsen from "./../images/box-scores/ASFFL_BoxScores_Wk11_berry-olsen.jpg"
import wk11GillanEvans from "./../images/box-scores/ASFFL_BoxScores_Wk11_gillan-evans.jpg"
import wk11HemsworthDowney from "./../images/box-scores/ASFFL_BoxScores_Wk11_hemsworth-downey.jpg"
import wk11HollandRussoo from "./../images/box-scores/ASFFL_BoxScores_Wk11_holland-russoo.jpg"
import wk11LiuKlementieff from "./../images/box-scores/ASFFL_BoxScores_Wk11_liu-klementieff.jpg"
import wk11MackieRudd from "./../images/box-scores/ASFFL_BoxScores_Wk11_mackie-rudd.jpg"
import wk11PrattReynolds from "./../images/box-scores/ASFFL_BoxScores_Wk11_pratt-reynolds.jpg"

import wk12DowneyLiu from "./../images/box-scores/ASFFL_BoxScores_Wk12_downey-liu.jpg"
import wk12EvansPratt from "./../images/box-scores/ASFFL_BoxScores_Wk12_evans-pratt.jpg"
import wk12KlementieffGillan from "./../images/box-scores/ASFFL_BoxScores_Wk12_klementieff-gillan.jpg"
import wk12OlsenHolland from "./../images/box-scores/ASFFL_BoxScores_Wk12_olsen-holland.jpg"
import wk12ReynoldsBerry from "./../images/box-scores/ASFFL_BoxScores_Wk12_reynolds-berry.jpg"
import wk12RuddHemsworth from "./../images/box-scores/ASFFL_BoxScores_Wk12_rudd-hemsworth.jpg"
import wk12RussoMackie from "./../images/box-scores/ASFFL_BoxScores_Wk12_russo-mackie.jpg"

import wk13DowneyPratt from "./../images/box-scores/ASFFL_BoxScores_wk13_downey-pratt.jpg"
import wk13EvansHolland from "./../images/box-scores/ASFFL_BoxScores_wk13_evans-holland.jpg"
import wk13KlementieffBerry from "./../images/box-scores/ASFFL_BoxScores_wk13_klementieff-berry.jpg"
import wk13OlsenHemsworth from "./../images/box-scores/ASFFL_BoxScores_wk13_olsen-hemsworth.jpg"
import wk13ReynoldsMackie from "./../images/box-scores/ASFFL_BoxScores_wk13_reynolds-mackie.jpg"
import wk13RuddGillan from "./../images/box-scores/ASFFL_BoxScores_wk13_rudd-gillan.jpg"
import wk13RussoLiu from "./../images/box-scores/ASFFL_BoxScores_wk13_russo-liu.jpg"

const week1 = [
  { image: wk1GillanRudd },
  { image: wk1HemsworthBerry },
  { image: wk1LiuPratt },
  { image: wk1MackieHolland },
  { image: wk1OlsenKlementieff },
  { image: wk1ReynoldsEvans },
  { image: wk1RussoDowney },
]

const week2 = [
  { image: wk2BerryHolland },
  { image: wk2DowneyReynolds },
  { image: wk2GillanHemsworth },
  { image: wk2KlementieffEvans },
  { image: wk2LiuRusso },
  { image: wk2PrattMackie },
  { image: wk2RuddOlsen },
]

const week3 = [
  { image: wk3BerryPratt },
  { image: wk3EvansRudd },
  { image: wk3HemsworthOlsen },
  { image: wk3HollandGillan },
  { image: wk3KlementieffDowney },
  { image: wk3MackieLiu },
  { image: wk3ReynoldsRusso },
]

const week4 = [
  { image: wk4BerryLiu },
  { image: wk4GillanPratt },
  { image: wk4HemsHolland },
  { image: wk4MackieReynolds },
  { image: wk4OlsenEvans },
  { image: wk4RuddDowney },
  { image: wk4RussoKlementieff },
]
const week5 = [
  { image: wk5BerryMackie },
  { image: wk5DowneyOlsen },
  { image: wk5GillanLiu },
  { image: wk5HollandEvans },
  { image: wk5PrattHemsworth },
  { image: wk5ReynoldsKlementieff },
  { image: wk5RussoRudd },
]
const week6 = [
  { image: wk6BerryKlementieff },
  { image: wk6EvansDowney },
  { image: wk6HemsworthLiu },
  { image: wk6HollandPratt },
  { image: wk6MackieGillan },
  { image: wk6OlsenRusso },
  { image: wk6ReynoldsRudd },
]

const week7 = [
  { image: berryGillan },
  { image: hemsworthMackie },
  { image: hollandLiu },
  { image: olsenReynolds },
  { image: prattDowney },
  { image: ruddKlementieff },
  { image: russoEvans },
]

const week8 = [
  { image: wk8BerryDowney },
  { image: wk8EvansMackie },
  { image: wk8GillanRusso },
  { image: wk8KlementieffHolland },
  { image: wk8OlsenLiu },
  { image: wk8PrattRudd },
  { image: wk8ReynoldsHemsworth },
]

const week9 = [
  { image: wk9BerryRudd },
  { image: wk9GillanOlsen },
  { image: wk9HemswoorthEvans },
  { image: wk9HollandDowney },
  { image: wk9LiuReynolds },
  { image: wk9MackieKlementieff },
  { image: wk9PrattRusso },
]

const week10 = [
  { image: wk10RuddHolland },
  { image: wk10EvansLiu },
  { image: wk10KlementieffHemsworth },
  { image: wk10OlsenPratt },
  { image: wk10ReynoldsGillan },
  { image: wk10RussoBerry },
  { image: wk10DowneymackieJpg },
]

const week11 = [
  { image: wk11BerryOlsen },
  { image: wk11GillanEvans },
  { image: wk11HemsworthDowney },
  { image: wk11HollandRussoo },
  { image: wk11LiuKlementieff },
  { image: wk11MackieRudd },
  { image: wk11PrattReynolds },
]
const week12 = [
  { image: wk12DowneyLiu },
  { image: wk12EvansPratt },
  { image: wk12KlementieffGillan },
  { image: wk12OlsenHolland },
  { image: wk12ReynoldsBerry },
  { image: wk12RuddHemsworth },
  { image: wk12RussoMackie },
]

const week13 = [
  { image: wk13DowneyPratt },
  { image: wk13EvansHolland },
  { image: wk13KlementieffBerry },
  { image: wk13OlsenHemsworth },
  { image: wk13ReynoldsMackie },
  { image: wk13RuddGillan },
  { image: wk13RussoLiu },
]

const data = [
  week13,
  week12,
  week11,
  week10,
  week9,
  week8,
  week7,
  week6,
  week5,
  week4,
  week3,
  week2,
  week1,
]

export default data
